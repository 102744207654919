import {SiteModule, SiteModuleCTA, UploadedImage, VideoSource, BackgroundStyles, TextColourStyles} from "../../../../types";

export type VideoGalleryEntrySection = {
  videoId: string;
  videoSource: VideoSource;
  videoUrl: string;
  videoTitle: string;
  posterImage: UploadedImage;
}

export enum VideoGalleryLayoutAlignment {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  CENTRE = 'CENTRE',
}

export enum VideoGalleryLayout {
  GRID = 'GRID',
}

export enum VideoGalleryDisplayOption {
  OVERLAY = 'OVERLAY',
}

export interface VideoGalleryModuleType extends SiteModule {
  title: string;
  titleSize: string;
  text: string;
  link: SiteModuleCTA;
  entries: VideoGalleryEntrySection[];
  videoGalleryLayout: VideoGalleryLayout;
  videoGalleryDisplayOption: VideoGalleryDisplayOption;
  videoGalleryLayoutAlignment: VideoGalleryLayoutAlignment;
  backgroundColour: BackgroundStyles;
  textColour: TextColourStyles;
}