import React, {useContext, useState, useMemo, useEffect} from 'react';
import {ThemeContext} from "styled-components";
import {
  SiteBuilderModule, StyledCallToAction, StyledHeading, StyledParagraph, VideoModal, VideoPlayer
} from '../sharedComponents';
import {VideoGalleryEntrySection, VideoGalleryModuleType} from "./types";
import {Wrapper, Line, Row, CTAWrapper} from "./index.style";
import {splitListIntoChunks} from "../../helpers";
import {isPrivateVideo, parseStreamlyUrl} from "../../../../utils/streamlyVideos";
import {useHistory, useLocation} from "react-router-dom";
import { allowScroll, preventScroll } from '../../../../helpers';
import {VideoCard} from "./innerComponents/VideoCard";

export interface VideoGalleryModuleProps {
  section: VideoGalleryModuleType;
}

export interface VideoState extends VideoGalleryEntrySection {
  streamlyUrl: string;
}

const VideoGalleryModule = (props: VideoGalleryModuleProps) => {
  const {
    section: {
      title,
      titleSize,
      text,
      link,
      backgroundColour = 'WHITE',
      textColour,
      entries = [],
      videoGalleryLayoutAlignment
    } = {},
  } = props;
  const history = useHistory();
  const { search, hash } = useLocation();
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [currentVideo, setCurrentVideo] = useState<VideoState | null>();
  const themeConfig = useContext(ThemeContext);
  const {
    visualStyles: vStyles,
    displayStyles: dStyles,
    textStyles
  } = themeConfig.siteBuilderModules.videoGalleryModule;

  useEffect(() => {
    const [prefix, videoId] = hash.split('-');
    const videoIsExist = entries.find((video) => video.videoId === videoId);

    if (prefix === '#video' && videoIsExist) {
      setModalOpened(true);
      const { sourceUrl } = parseStreamlyUrl(videoIsExist.videoUrl);
      setCurrentVideo({ ...videoIsExist, streamlyUrl: sourceUrl });
    }
    // eslint-disable-next-line
  }, []);

  const toggleModal = (video: VideoGalleryEntrySection) => {
    const { videoUrl, videoId } = video;
    const { accessType, sourceUrl } = parseStreamlyUrl(videoUrl);

    // Only for Streamly private videos
    if (isPrivateVideo(accessType)) {
      window.open(sourceUrl, '_blank');
      return;
    }

    if (modalOpened) {
      allowScroll();
      history.push(`${search}#`);
      setCurrentVideo(null);
    } else {
      preventScroll();
      history.push(`${search}#video-${videoId}`);
      setCurrentVideo({ ...video, streamlyUrl: sourceUrl });
    }

    setModalOpened((prev) => !prev);
  };

  // Only with chunks videoGalleryLayoutAlignment will work
  const videosWithChunks = useMemo(() => splitListIntoChunks(entries, 3), [entries]);

  return (
    <SiteBuilderModule
      section={props.section}
      moduleTheme={{
        style: backgroundColour,
        textColour,
        vStyles,
        dStyles,
        textStyles,
        layoutAlignment: videoGalleryLayoutAlignment
      }}
    >
      <Wrapper data-testid="video-gallery-module">
        <div className="container">
          {title && (
            <div className="row">
              <div className="col-xs-12">
                <StyledHeading
                  content={title}
                  as={titleSize}
                  testId="video-gallery-title"
                />
                <Line data-testid="video-line" />
              </div>
            </div>
          )}
          {
            text && (
              <div className="row">
                <div className="col-xs-12">
                  <StyledParagraph displayStyles={dStyles} content={text} testId="video-gallery-text" />
                </div>
              </div>
            )
          }
          {
            videosWithChunks.map((list, index) => (
              <Row className="row" key={index}>
                {
                  list.map((video: VideoGalleryEntrySection, videoIndex) => (
                    <VideoCard key={videoIndex} {...video} onClick={toggleModal} />
                  ))
                }
              </Row>
            ))
          }
          {link?.shown && (
            <CTAWrapper>
              <div className="row">
                <div className="col-xs-12">
                  <StyledCallToAction ctaConfig={link} testId="video-gallery-cta" displayStyles={{}} visualStyles={{}} />
                </div>
              </div>
            </CTAWrapper>
          )}
        </div>
        {(modalOpened && currentVideo) && (
          <VideoModal onClose={toggleModal as () => void}>
            <VideoPlayer
              source={currentVideo.videoSource}
              id={currentVideo.videoId}
              title={title}
              streamlyUrl={currentVideo.streamlyUrl}
            />
          </VideoModal>
        )}
      </Wrapper>
    </SiteBuilderModule>
  );
};

export default VideoGalleryModule;
